export const grados = ['1er Grado', '2do Grado', '3er Grado', '4to Grado', '5to Grado'];
export const cursos = {
	ARIT: 'Aritmética',
	ALGB: 'Álgebra',
	GEOM: 'Geometría',
	TRIG: 'Trigonometría',
	RM: 'Razonamiento Matemático',
	FIS: 'Física',
	QUIM: 'Química'
};
let _2do5to = ['ARIT', 'ALGB', 'GEOM', 'TRIG', 'RM', 'FIS', 'QUIM'];

export const cursosPorGrado = {
	'1er Grado': ['ARIT', 'ALGB', 'GEOM', 'RM'].map(e=>"1"+e),
	'2do Grado': _2do5to.map(e=>"2"+e),
	'3er Grado': _2do5to.map(e=>"3"+e),
	'4to Grado': _2do5to.map(e=>"4"+e),
	'5to Grado': _2do5to.map(e=>"5"+e)
};
