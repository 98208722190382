import React from 'react';
import { grados, cursos, cursosPorGrado } from './cursos';
import { ListaCursos, GradoStyle, CursoStyle } from './style';
import { useHistory } from 'react-router-dom';

const CursosGrado = ({ grado, history }) => {
	return (
		<ListaCursos>
			{cursosPorGrado[grado].map((cod_curso, key) => {
				return (
					<CursoStyle key={key}
						onClick={() => {
							history.push(`/curso/${grado}/${cod_curso}`);
						}}
					>
						{cursos[cod_curso.slice(1)]}
					</CursoStyle>
				);
			})}
		</ListaCursos>
	);
};
const Principal = () => {
	let history = useHistory();
	return (
		<div>
			<h4 style={{ textAlign: 'center' }}>Verano 2020 - Reforzamiento Verano</h4>
			{grados.map((grado, key) => {
				return (
					<GradoStyle key={key}>
						<div>{grado}</div>
						<CursosGrado grado={grado} history={history} />
					</GradoStyle>
				);
			})}
		</div>
	);
};

export default Principal;
