import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/atoms';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
const Panel = styled.div`
	margin: 0 auto;
	border-radius: 10px;
	background-color: #e3e3e3;
	max-width: 450px;
	padding: 25px;
`;
const Field = styled.div`
	margin: 10px 0px;
	> label {
		width: 100px;
		display: inline-block;
	}
`;

const AdminLogin = () => {
	const [credentials, setCredentials] = useState({
		usuario: '',
		password: ''
	});
	const [session, setSession] = useState({
		token: localStorage.getItem('session'),
		expires: localStorage.getItem('session-expires')
	});
	const handleLogin = async ({ usuario, password }) => {
		try {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/adminlogin`, {
				usuario: usuario,
				password: password
			});

			if (response.data.success) {
				let { token, expires } = response.data;
				localStorage.setItem('session', token);
				localStorage.setItem('session-expires', expires);
				setSession({
					token: token,
					expires: expires
				});
			} else {
				alert('Usuario o contraseña erronea');
			}
		} catch (error) {
			console.error(error.response);
		}
	};
	console.log('SESION', session.token, session.expires, Number(new Date()));
	if (session.token && session.expires >= new Date()) {
		return <Redirect to="/admin/material" />;
	} else {
		localStorage.removeItem('session');
		localStorage.removeItem('session-expires');
		return (
			<Panel>
				<Field>
					<label>Usuario</label>
					<input
						value={credentials.usuario}
						onChange={e => {
							let value = e.target.value;
							setCredentials(prev => {
								return { ...prev, usuario: value };
							});
						}}
					/>
				</Field>
				<Field>
					<label>Password</label>
					<input
						value={credentials.password}
						onChange={e => {
							let value = e.target.value;
							setCredentials(prev => ({ ...prev, password: value }));
						}}
						type="password"
						onKeyPress={(e)=>{
							if(e.key === "Enter"){
								handleLogin(credentials)
							}
						}}
					/>
				</Field>
				<div>
					<Button onClick={() => handleLogin(credentials)}>Login</Button>
				</div>
			</Panel>
		);
	}
};

export default AdminLogin;
