import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/atoms';
const NavbarStyle = styled.nav`
	padding: 5px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: burlywood;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 80px;
	z-index: 1;
	> img {
		height: 70px;
	}
	* {
		display: inline;
	}
`;
const Navbar = () => {
	let history = useHistory();
	return (
		<NavbarStyle>
			<img src="/logo_uni.png" alt="UNI Logo" />
			<div>
				<Button
					onClick={() => {
						history.push('/');
					}}
					style={{ marginLeft: '30px' }}
				>
					Lista de Cursos
				</Button>
				<Button
					onClick={() => {
						localStorage.clear();
						history.push('/');
					}}
					style={{ marginLeft: '30px' }}
				>
					Salir
				</Button>
			</div>
		</NavbarStyle>
	);
};
export default Navbar;
