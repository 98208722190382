import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import predefinedToast from 'utils/toast';
import { Button, Icon, List, ListItem, useToast } from '@chakra-ui/core';
const CursosWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
`;
const MaterialesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0px;
`;
const NuevoMaterialWrapper = styled.div`
	display: flex;
	max-width: 500px;
	margin: 10px 0px;
	padding: 10px 0px;
`;

const ListItemStyle = styled.div`
	max-width: 600px;
	display: inline;
	li {
		margin: 5px 0px;
		padding: 5px 15px;
		border-radius: 15px;
		background-color: lightgray;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;
const UploadStyle = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	> * {
		margin: 5px 10px;
	}
`;

const Materiales = ({ grados, grado }) => {
	let inputFile;
	const toast = useToast();
	const [curso, setCurso] = useState();
	const [selectedFile, setSelectedFile] = useState({
		file: undefined,
		filename: '',
		loaded: false,
		uploading: false
	});
	const createFile = async (grado, codigoCurso) => {
		const data = new FormData();
		data.append('materialFile', selectedFile.file);
		data.append('grado', grado);
		data.append('codigoCurso', codigoCurso);
		try {
			setSelectedFile(prev => ({
				...prev,
				loaded: false,
				uploding: true
			}));
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/material/crear/`, data);
      if(response.data && response.data.success){
        setCurso(prev => {
          let copy = { ...prev };
          copy.materiales = response.data.data;
          return copy;
        });
      }
      
			toast(
				predefinedToast({
					title: 'Archivo subido 📙'
				})
			);
			setSelectedFile({
				file: undefined,
				loaded: false,
				uploading: false,
				filename: ''
			});
		} catch (err) {
			alert('Error subiendo archivo');
		}
	};
	const deleteFile = async (grado, codigoCurso, materialID) => {
		try {
			let response = await axios.post(`${process.env.REACT_APP_API_URL}/material/borrar/`, {
				grado,
				codigoCurso,
				materialID
			});
			if (response.data && response.data.success) {
				toast(
					predefinedToast({
						title: 'Borrado con éxito',
						description: ''
					})
				);
				setCurso(prev => {
					let copy = { ...prev };
					prev.materiales.splice(
						copy.materiales.findIndex(e => {
							return e._id === materialID;
						}),
						1
					);
					return copy;
				});
			} else {
				alert('No se encontró el material, posiblemente ya esté borrado, actualice la página');
			}
		} catch (error) {
			console.error(error.response);
			alert('Ocurrió un error al borrar');
		}
	};
	useEffect(() => {
		setCurso(null);
	}, [grado]);
	if (grado) {
		return (
			<div>
				<div style={{ textAlign: 'center', margin: '20px' }}>
					Grado: <strong>{grados[grado - 1].grado}</strong>
				</div>
				<CursosWrapper>
					{grados[grado - 1].cursos.map((curso, key) => {
						return (
							<div key={key}>
								<Button
									onClick={() => {
										setCurso(curso);
										console.log('curso', curso);
									}}
								>
									{curso.nombreCurso}
								</Button>
							</div>
						);
					})}
				</CursosWrapper>
				<MaterialesWrapper>
					{curso ? (
						<>
              <div>Password: {curso.password}</div>
							<ListItemStyle>
								<List as="ol" styleType="decimal">
									{curso.materiales.map((material, key) => {
										return (
											<ListItem key={key}>
												{material.nombreMaterial}
												<Button
													mx={4}
													onClick={() => {
														deleteFile(grado, curso.codigoCurso, material._id);
													}}
												>
													<Icon name="delete" />
												</Button>
											</ListItem>
										);
									})}
								</List>
							</ListItemStyle>
						</>
					) : null}
				</MaterialesWrapper>
				<NuevoMaterialWrapper>
					{curso ? (
						<UploadStyle>
							<Button
								onClick={() => {
									inputFile.click();
								}}
							>
								Click para cargar
							</Button>
							<input
								type="file"
								ref={input => (inputFile = input)}
								style={{ display: 'none' }}
								onChange={event => {
									let file = event.target.files[0];
									setSelectedFile({
										file: file,
										filename: file.name,
										loaded: true,
										uploading: false
									});
									event.target.value = null;
								}}
							/>
							{selectedFile.filename ? <div>{selectedFile.filename}</div> : null}
							<Button
								isDisabled={!selectedFile.loaded}
								isLoading={selectedFile.uploading}
								onClick={() => {
									if (!selectedFile) {
										alert('No ha seleccionado archivo');
									} else {
										createFile(grado, curso.codigoCurso, selectedFile.file);
									}
								}}
							>
								Agregar nuevo material
							</Button>
						</UploadStyle>
					) : (
						<div>Para subir material seleccione un curso</div>
					)}
				</NuevoMaterialWrapper>
			</div>
		);
	} else {
		return <div>Seleccione un grado para cargar materiales</div>;
	}
};

export default Materiales;
