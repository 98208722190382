import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'components/atoms';
import styled from 'styled-components';
import axios from 'axios';
import { cursos } from '../principal/cursos';
const LoginCurso = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 350px;
	> * {
		margin: 10px 5px;
	}
`;
const MaterialCurso = styled.div`
	display: flex;
`;
const fetchMaterial = async token => {
	try {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/recursos?token=${token}`);
		if (response.data && response.data.success) {
			return response.data.data;
		} else {
			throw new Error();
		}
	} catch (error) {
		console.log(error.response);
	}
};
const getToken = async (grado, curso, password, setSession) => {
	try {
		let response = await axios.post(`${process.env.REACT_APP_API_URL}/loginCurso/${curso}`, {
			passwordCurso: password
		});
		if (response.data && response.data.success) {
			let { token, expires } = response.data;
			console.log('TOKEN', token, expires);
			localStorage.setItem(`${grado}-${curso}`, token);
			localStorage.setItem(`${grado}-${curso}-expiry`, expires);
			setSession({
				token: token,
				expiry: expires
			});
		} else {
			alert('Password errónea');
		}
	} catch (error) {
		console.error(error);
		alert('Ocurrió un error, contactar a soporte');
	}
};
const Material = ({ grado, curso, token }) => {
	let [material, setMaterial] = useState({ fetched: false });
	useEffect(() => {
		const fetch = async () => {
			let documentos = await fetchMaterial(token);
			setMaterial({ fetched: true, documentos });
		};
		fetch();
	}, [token]);
	const downloadFile = (grado, curso, materialID,nombreMaterial, token) => {
		axios({
			url: `${process.env.REACT_APP_API_URL}/material/${grado}/${curso}/${materialID}?token=${token}`,
			method: 'GET',
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${nombreMaterial}`);
			document.body.appendChild(link);
			link.click();
		});
	};
	return (
		<MaterialCurso>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div style={{ margin: '10px' }}>
					Material: {grado} Curso: {cursos[curso]}
				</div>
				{!material.fetched ? (
					'Cargando Material...'
				) : (
					<div>
						{material.documentos.map((e, index) => (
							<div key={index} style={{margin: "10px 0px"}}>
								<Button onClick={() => downloadFile(grado, curso, e._id, e.nombreMaterial, token)}>{e.nombreMaterial}</Button>
							</div>
						))}
					</div>
				)}
			</div>
		</MaterialCurso>
	);
};
const Curso = () => {
	let { grado, curso } = useParams();
	let [sessionCurso, setSession] = useState({
		token: localStorage.getItem(`${grado}-${curso}`),
		expiry: Number(localStorage.getItem(`${grado}-${curso}-expiry`))
	});
	let [password, setPassword] = useState('');

	const handleOnClickIngresar = async (grado, curso, password) => {
		await getToken(grado, curso, password, setSession);
	};
	if (!sessionCurso.token || new Date() > sessionCurso.expiry) {
		console.log('WHAT?');
		localStorage.removeItem(`${grado}-${curso}`);
		localStorage.removeItem(`${grado}-${curso}-expiry`);
		return (
			<LoginCurso>
				<div>
					Material: {grado} Curso: {cursos[curso.slice(1)]}
				</div>
				<input
					placeholder="Escribe la password del curso"
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>
				<Button onClick={() => handleOnClickIngresar(grado, curso, password)}>Ingresar</Button>
			</LoginCurso>
		);
	} else {
		return <Material grado={grado} curso={curso} token={sessionCurso.token} />;
	}
};

export default Curso;
