const Toast = ({title, description, status}) => {
	return {
		title: title,
		description: description,
		status: status,
		duration: 5000,
		isClosable: true
	};
};

export default Toast;
