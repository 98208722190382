import styled from 'styled-components';
export const GradoStyle = styled.div`
	border: 1px solid white;
	flex-direction: column;
	background-color: #e8e8e8;
	border-radius: 10px;
	display: flex;
	padding: 20px;
	margin: 10px 0px;
`;
export const ListaCursos = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
`;
export const CursoStyle = styled.div`
	border-radius: 20px;
	padding: 10px 15px;
	background-color: green;
	color: white;
	margin: 10px;
	cursor: pointer;
	:hover {
		background-color: lightgreen;
		color: black;
	}
`;
