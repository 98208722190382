import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Materiales from './materiales';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/core';
import { Redirect } from 'react-router-dom';

const fetchMateriales = async () => {
	try {
		let response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener-cursos`);
		return response.data;
	} catch (error) {
		return error.response;
	}
};

const Grados = ({ grados }) => {
	const [grado, setGrado] = useState();
	const handleGrado = g => setGrado(g);

	return (
		<>
			<Menu>
				<MenuButton my={5} as={Button} rightIcon="chevron-down">
					Seleccionar grado
				</MenuButton>
				<MenuList>
					<MenuItem onClick={() => handleGrado(1)}>1er Grado</MenuItem>
					<MenuItem onClick={() => handleGrado(2)}>2do Grado</MenuItem>
					<MenuItem onClick={() => handleGrado(3)}>3er Grado</MenuItem>
					<MenuItem onClick={() => handleGrado(4)}>4to Grado</MenuItem>
					<MenuItem onClick={() => handleGrado(5)}>5to Grado</MenuItem>
				</MenuList>
			</Menu>
			{<Materiales grados={grados} grado={grado} />}
		</>
	);
};
const Material = () => {
	const [session, ] = useState({
		token: localStorage.getItem('session'),
		expires: localStorage.getItem('session-expires')
	});
	const [grados, setGrados] = useState();
	useEffect(() => {
		const fetch = async () => {
			let grados = await fetchMateriales();
			if (grados && grados.success) {
				setGrados(grados.data);
			} else {
				alert('Ocurrió un error al obtener grados y cursos');
			}
		};
		fetch();
	}, []);
	if (session.token && session.expires >= new Date()) {
		return (
			<div>
				<div>Administración de materiales</div>
				{grados ? <Grados grados={grados} /> : null}
			</div>
		);
	} else {
		return <Redirect to="/admin" />;
	}
};

export default Material;
