import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { Navbar } from './components';
import { Principal, Curso, AdminLogin, AdminMaterial } from './pages';

function App({ children }) {
	return (
		<ThemeProvider>
			<CSSReset />
			<Router>
				<Navbar />
				<div style={{ marginTop: '100px' }}>
					<Switch>
						<Route exact path="/">
							<Principal />
						</Route>
						<Route path="/curso/:grado/:curso">
							<Curso />
						</Route>
						<Route exact path="/admin">
							<AdminLogin />
						</Route>
						<Route exact path="/admin/material">
							<AdminMaterial />
						</Route>
						<Route path="*">
							<div>
								No deberías estar aquí{' '}
								<span role="img" aria-label="espía">
									🕵️
								</span>
							</div>
						</Route>
					</Switch>
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;
